import React from 'react';
import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet-async";
import CommonHelmet from '../../components/CommonHelmet';
import { TypeAnimation } from 'react-type-animation';
// import ReactTextRotator from "react-text-rotator";
// import TextTransition, { presets } from 'react-text-transition';
import CountUp from 'react-countup';

// Design Files
// import { useMenu } from '../../components/Header/MenuContext';
import Tradeslider from '../Sliders/Tradeslider';
import Commonsection from '../Commonsection';

// Images Common File
import * as Img from '../../components/Img';

// import video1 from '../../img/video1.mp4';
import videoImg1 from '../../img/video1.webp';

// import night from '../../img/bgimg/night-earth-global-virtual-inter.webp';
// import video1 from '../../img/video1.mp4';
// import videoImg1 from '../../img/video1.webp';

// import csf from '../../img/bgimg/sfa.jpg';

import Component1 from '../../img/icons/layer/Component(1).svg';
import Component2 from '../../img/icons/layer/Component(2).svg';
// import Component3 from '../../img/icons/layer/Component(3).svg';
import Component4 from '../../img/icons/layer/Component(4).svg';
import Component5 from '../../img/icons/layer/Component(6).png';
import faviconboxsadow from '../../img/logo/favicon-boxsadow.svg';
import BenefitsSlider from './BenefitsSlider';
import NewsInsightsSlider from './NewsInsightsSlider';


// Images
import client1 from '../../img/client/Untitled-1.jpg';
import client2 from '../../img/client/Untitled-2.jpg';
import client3 from '../../img/client/Untitled-3.jpg';
import client6 from '../../img/client/Untitled-6.jpg';


import AwardSlider from '../../components/AwardSlider';



export default function PageHome() {

  // const TEXTS = [
  //   <>Your Path To Profitable <br/> Trading Starts Here</>, 
  //   <>Empower Your Portfolio: <br/> Unleash the Future of Trading</>, 
  //   <>Trade Smart: Elevate <br/> Your Financial Potential</>
  // ];

  // const { handleScreenChange } = useMenu();

  // const content = [
  //   {
  //     text: ( <> Your Path To Profitable <br/> Trading Starts Here </> ),
  //     animation: "fade",
  //   },
  //   {
  //     text: ( <> Empower Your Portfolio: <br/> Unleash the Future of Trading </> ),
  //     animation: "fade",
  //   },
  //   {
  //     text: ( <> Trade Smart: Elevate <br/> Your Financial Potential </> ),
  //     animation: "fade",
  //   }
  // ];

  // const [index, setIndex] = React.useState(0);

  // React.useEffect(() => {
  //   const intervalId = setInterval(
  //     () => setIndex((index) => index + 1),
  //     3000, // every 3 seconds
  //   );
  //   return () => clearTimeout(intervalId);
  // }, []);

  return (
    <>
      {/* <Helmet>
        <title>WinproFx</title>
      </Helmet> */}
      <CommonHelmet
        title="Best Forex Trading Online Company | Forex Broker | WINPROFX"
        description="Discover the Best Forex Trading Company for seamless Forex Trading Online. Invest in top-rated platforms and secure trading environments. Contact us."
        keywords="Best Forex Trading Platform in India, Best Indices Trading Platform in India, Best Crypto Trading Platform in India, Best Metals Trading Platform in India, Best Commodities Trading Platform in India, Best Oil Trading Platform in India, Best Forex and Crypto Trading Platform in India, Best Trading Platform for Commodities in India, Best Platform for Trading Indices in India, Best Trading Platform for Metals and Oil in India, Best Online Trading Platform in India, Best Forex and Commodities Trading Platform in India, Best Crypto and Forex Trading Platform in India, Best Trading Platform with Tight Spreads in India, Best Trading Platform for Beginners in India"
      />

      <div className='d-none' style={{ display: "none" }}>
        <h1>Best Forex Trading Online Company in India | WINPROFX</h1>
        <h2>Discover the Best Forex Broker in India for seamless Forex Trading online. Invest in top-rated platforms and secure trading environments. Contact us.</h2>
        <b>Forex</b>
        <b>Treading</b>
        <b>Start Trading</b>
        <b>Benefits of Trading</b>
        <b>TRADE</b>
        <b>Brokers</b>

        <i>TRADE</i>
        <i>Forex</i>
        <img src="" alt="Best Forex Trading Platform in India, Best Indices Trading Platform in India, Best Crypto Trading Platform in India, Best Metals Trading Platform in India, Best Commodities Trading Platform in India, Best Oil Trading Platform in India, Best Forex and Crypto Trading Platform in India, Best Trading Platform for Commodities in India, Best Platform for Trading Indices in India, Best Trading Platform for Metals and Oil in India, Best Online Trading Platform in India, Best Forex and Commodities Trading Platform in India, Best Crypto and Forex Trading Platform in India, Best Trading Platform with Tight Spreads in India, Best Trading Platform for Beginners in India" />
      </div>

      {/* <section className="d-block d-sm-none map-main-section miness-margine maxwidth1920">
        <img data-aos="fade-up" src={Img.map} alt="" />
      </section> */}

      <div className="vsdgfhtjhdtrhsr v-before-none">
        {/* <video poster={videoImg1} autoPlay muted loop playsInline>
          <source src={video1} type='video/mp4' /> 
        </video> */}
        <img style={{ maxWidth: "2560px" }} src={videoImg1} alt="" />
      </div>

      <section>
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx">
            <div data-aos="fade-up" className="tlm-heading" style={{ height: '96px', display: 'flex', alignItems: 'center', }}>
              <TypeAnimation
                sequence={[
                  'Your Path To Profitable \n Trading Starts Here',
                  700,
                  'Empower Your Portfolio: \n Unleash the Future of Trading',
                  700,
                  'Trade Smart: Elevate \n Your Financial Potential ',
                  700,
                ]}
                wrapper="span"
                style={{ whiteSpace: 'pre-line', display: 'block', overflow: 'hidden' }}
                speed={60}
                repeat={Infinity}
              />
              {/* <ReactTextRotator content={content} /> */}
              {/* <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition> */}
            </div>
            {/* <div className="tlm-heading">Your Path To <span className="tlm-h-color">Profitable</span> <br/> Trading Starts Here</div> */}
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">WinproFX is a Globally Reputed Broker Offering Tightest Spreads, Lightning Fast Withdrawals, 300+ Tradable Instruments With Nanosecond Execution on Your Finger tips.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
              {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
              <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Open Account</span></a>
              {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
              {/* <Link to="/free-funded-accounts" className="tlm-btn-items tbi-outline" tbidata="Free Funded accounts"><span className='tbidata'>Free Funded accounts</span><span>Free Funded accounts</span></Link> */}
              {/* <div className="tlm-btn-items tbi-none">Try Demo <img src={Img.arrowright} alt="" /> </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section style={{zIndex:"-1"}}  className="relative d-none d-sm-block map-main-section miness-margine top-miness-margine maxwidth1920">
        <img data-aos="fade-up" src={Img.map} alt="" />
      </section> */}

      <section className="pt-sm-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="gradient-section">
            <div className="common-heading-bx chb-fd-row">
              <div className="common-heading">How Can You Start Making <br /> <span className="ch-color">Money</span> With Us</div>
              {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="d-none d-md-flex common-btn-item cbi-fill"><span>Open Account</span></div> */}
              <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="d-none d-md-flex common-btn-item cbi-fill"><span>Open Account</span></a>
              {/* <div onClick={() => handleScreenChange('register-screen')} className="d-none d-md-flex common-btn-item cbi-fill"><span>Open Account</span></div> */}
            </div>

            <div className="row mt-5">

              <div data-aos="fade-up" className="col-md-4 col-sm-6 mb-5">
                <div className="card-item-money">
                  <div className="card-item">
                    <img className="card-i-img" src={Img.layer1} alt="" />
                    <div className="card-i-heading">Open an account</div>
                    <div className="card-i-text max-width-189">Click the button above and Just follow some easy steps.</div>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-delay="400" className="col-md-4 col-sm-6 mb-5">
                <div className="card-item-money">
                  <div className="card-item">
                    <img className="card-i-img" src={Img.layer2} alt="" />
                    <div className="card-i-heading">Make a deposit</div>
                    <div className="card-i-text max-width-189">Pro Tip: USDT Deposits and Withdrawals are the Fastest.</div>
                  </div>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-delay="500" className="col-md-4 col-sm-6 mb-5">
                <div className="card-item-money">
                  <div className="card-item">
                    <img className="card-i-img" src={Img.layer3} alt="" />
                    <div className="card-i-heading">Start Trading</div>
                    <div className="card-i-text max-width-189">Every professional started from scratch So Start Trading Now.</div>
                  </div>
                </div>
              </div>

            </div>
            <div className="common-heading-bx chb-fd-row mb-4">
              {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" target='_blank' rel="noreferrer" className="mx-auto d-flex d-md-none common-btn-item cbi-fill"><span>Open Account</span></div> */}
              <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="mx-auto d-flex d-md-none common-btn-item cbi-fill"><span>Open Account</span></a>
              {/* <div onClick={() => handleScreenChange('register-screen')} className="mx-auto d-flex d-md-none common-btn-item cbi-fill"><span>Open Account</span></div> */}
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section bg-img bg-img-asiamap ">
            <div className="common-heading-bx chb-border-bottom">
              <div className="common-heading">Invest With Fastest Growing Broker <br /> <span className="ch-color ch-size-big">Asia 2024</span></div>
            </div>
            <div className="row mt-5">

              <div data-aos="fade-up" className="col-lg-3 col-md-3 col-6 mb-5">
                <div className="card-item text-center">
                  <img className="card-i-img img-64" src={Img.layer4} alt="" />
                  <div className="card-i-heading heading-32">Evolving</div>
                  <div className="card-i-text text-16">In The market</div>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-delay="400" className="col-lg-3 col-md-3 col-6 mb-5">
                <div className="card-item text-center">
                  <img className="card-i-img img-64" src={Img.layer5} alt="" />
                  <div className="card-i-heading heading-32"><CountUp enableScrollSpy end={40} />+</div>
                  <div className="card-i-text text-16">Awards</div>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-delay="500" className="col-lg-3 col-md-3 col-6">
                <div className="card-item text-center">
                  <img className="card-i-img img-64" src={Img.layer6} alt="" />
                  <div className="card-i-heading heading-32"><CountUp enableScrollSpy end={105} />+</div>
                  <div className="card-i-text text-16">Countries Covered</div>
                </div>
              </div>

              <div data-aos="fade-up" data-aos-delay="600" className="col-lg-3 col-md-3 col-6">
                <div className="card-item text-center">
                  <img className="card-i-img img-64" src={Img.layer7} alt="" />
                  <div className="card-i-heading heading-32"><CountUp enableScrollSpy end={1} />M+</div>
                  <div className="card-i-text text-16">Trading Accounts</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5 overflowhidden">
        <div className="container-lg cl-custome">

          <div className="common-section">

            <div className="row justify-content-center">

              <div className="col-lg-4 col-md-5">
                <div className="common-heading-bx pb-5 pb-md-0">
                  <div className="common-heading">Benefits of <span className="ch-color">Trading</span> <br /> With Us</div>
                  <div className="common-sub-heading mt-md-4">Discover seamless trading with <br /> us, where transparency and <br /> advanced technology meet for <br /> your financial success.</div>
                  {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="d-none d-md-flex common-btn-item cbi-outline mt-md-4" cbidata={"Start Trading"}><span>Start Trading<img src={Img.arrowright} alt="" /></span></div> */}
                  <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="d-none d-md-flex common-btn-item cbi-outline mt-md-4" cbidata={"Start Trading"}><span>Start Trading<img src={Img.arrowright} alt="" /></span></a>
                  {/* <div onClick={() => handleScreenChange('register-screen')} className="d-none d-md-flex common-btn-item cbi-outline mt-md-4" cbidata={"Start Trading"}><span>Start Trading<img src={Img.arrowright} alt="" /></span></div> */}
                </div>
              </div>

              <div className="col-lg-6 col-md-7">
                <div className="row mt-4 mt-md-0 d-none d-md-flex">

                  <div data-aos="fade-up-left" className="col-sm-6">
                    <div className="card-item-flip">
                      <div className="ctf-inner">
                        <div className="card-item cif-befor">
                          <img className="card-i-img img-32" src={Component1} alt="" />
                          <div>
                            <div className="card-i-text max-width-189">Crypto Withdraw in</div>
                            <div className="card-i-heading">30 minutes</div>
                          </div>
                        </div>
                        <div className="card-item cif-after">
                          <img className="card-i-img img-32" src={Component1} alt="" />
                          <div className="card-i-text max-width-189">Crypto Withdraw in</div>
                          <div className="card-i-heading">30 minutes</div>
                          <div className="card-i-text">"Unlock swift financial flexibility with our 30-minute withdrawal guarantee."</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div data-aos="fade-up-left" className="col-sm-6">
                    <div className="card-item-flip">
                      <div className="ctf-inner">
                        <div className="card-item cif-befor">
                          <img className="card-i-img" src={Component2} alt="" />
                          <div>
                            <div className="card-i-text">Trade daily</div>
                            <div className="card-i-heading">Win rewards</div>
                          </div>
                        </div>
                        <div className="card-item cif-after">
                          <img className="card-i-img" src={Component2} alt="" />
                          <div className="card-i-text">Trade daily</div>
                          <div>
                            <div className="card-i-heading">Win rewards</div>
                            <div className="card-i-text">"Daily trades, daily wins – fuel your success in every market move."</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div data-aos="fade-up-left" className="col-sm-6">
                    <div className="card-item-flip">
                      <div className="ctf-inner">
                        <div className="card-item cif-befor">
                          <img className="card-i-img" src={Component5} alt="" />
                          <div>
                            <div className="card-i-text">Award Winning</div>
                            <div className="card-i-heading">Customer Support</div>
                          </div>
                        </div>
                        <div className="card-item cif-after">
                          <img className="card-i-img" src={Component5} alt="" />
                          <div className="card-i-text">Award Winning</div>
                          <div className="card-i-heading">Customer Support</div>
                          <div className="card-i-text">Delivering unparalleled service excellence!</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div data-aos="fade-up-left" className="col-sm-6">
                    <div className="card-item-flip">
                      <div className="ctf-inner">
                        <div className="card-item cif-befor">
                          <img className="card-i-img" src={Component4} alt="" />
                          <div>
                            <div className="card-i-text">Negative balance</div>
                            <div className="card-i-heading">Protection</div>
                          </div>
                        </div>
                        <div className="card-item cif-after">
                          <img className="card-i-img" src={Component4} alt="" />
                          <div className="card-i-text">Negative balance</div>
                          <div className="card-i-heading">Protection</div>
                          <div className="card-i-text">"Trade confidently with built-in negative balance protection for financial security."</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="d-block d-md-none pb-4">
                  <BenefitsSlider />
                </div>
              </div>

            </div>

            <div className="common-heading-bx">
              {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="d-flex d-md-none common-btn-item cbi-outline mt-md-4" cbidata={"Start Trading"}><span>Start Trading<img src={Img.arrowright} alt="" /></span></div> */}
              <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="d-flex d-md-none common-btn-item cbi-outline mt-md-4" cbidata={"Start Trading"}><span>Start Trading<img src={Img.arrowright} alt="" /></span></a>
              {/* <div onClick={() => handleScreenChange('register-screen')} className="d-flex d-md-none common-btn-item cbi-outline mt-md-4" cbidata={"Start Trading"}><span>Start Trading<img src={Img.arrowright} alt="" /></span></div> */}
            </div>

          </div>

        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5 bg-img bg-img-mount">
        <div className="container-lg cl-custome">

          <div className="common-section">

            <div className="common-heading-bx pb-5 pb-md-0">
              <div className="common-heading">WHAT YOU CAN <span className="ch-color">TRADE?</span></div>
              <div className="common-sub-heading">We're here to help during market volatility With WinproFX You Can Trade On <br /> Major Markets Across 3 Assets Classes Including FX, Metals, And CFDs.</div>
            </div>

            <Tradeslider />

          </div>

        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5 overflowhidden">
        <div className="container-lg cl-custome">
          <div className="common-section cs-bg-color cs-radius-32px">

            <div className="row">

              <div className="col-lg-8 col-md-7 p-4 p-md-5 order-md-1 order-2">
                <div className="common-heading-bx">
                  <div className="common-heading">ABOUT <span className="ch-color-gradient">WINPROFX</span></div>
                  <div className="common-sub-heading">Limitless opportunity for excessive growth</div>
                </div>

                <div className="common-date-text mt-4">At WinproFX, we offer a secure online trading platform, fostering sustainability through expertise, market knowledge, and trusted partnerships.</div>

                <ul className="common-date-ul mt-4">
                  {/* <li>Suitable for experienced traders.</li> */}
                  <li>Best platform for Every Class of Traders Beginners, intermediate or Professional.</li>
                  <li>Sophisticated order management tools.</li>
                  <li>Introducing more native technical indicators.</li>
                  <li>Easy to configure and customize your trading setup</li>
                </ul>

                <Link to="/about" className="common-btn-item cbi-outline  mt-4" cbidata="Explore more"><span>Explore more<img src={Img.arrowright} alt="" /></span></Link>

              </div>

              <div className="col-lg-4 col-md-5 order-md-2 order-1">
                <div className="about-img-section d-flex justify-content-cneter align-item-center">

                  <img data-aos="fade-left" src={faviconboxsadow} alt="" />

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5 bg-img bg-img-asiamap">
        <div className="container-lg cl-custome ">

          <div className="common-section mt-4">

            <div className="common-heading-bx">
              <div className="common-heading text-center">WinproFX Broker with <span className="ch-color">Award-Winning</span> Excellence</div>
              <div className="common-sub-heading text-center">The global forex industry has acknowledged WinproFX's achievements, <br /> honoring us with top accolades for our exceptional products and services.</div>
            </div>

            {/* <div className="award-section mt-4">
              <div className="as-left">
                <div className="as-l-img">
                  <img data-aos="fade-right" src={tropyleaf} alt='' />

                  <svg className="asl-svg-vert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 1000" preserveAspectRatio="none">
                    <path d="M6,0H0v1000h100L6,0z"></path>
                  </svg>

                  <svg className="asl-svg-hori" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path d="M0,6V0h1000v100L0,6z"></path>
                  </svg>
                </div>
              </div>
              <div className="as-right">
                <div className="award-item">
                  <div className="award-item">
                    <div data-aos="fade-up" className="ai-img">
                      <img src={award} alt="" />
                    </div>
                    <div data-aos="fade-up" className="ai-data">
                      <div className="ai-d-heading">Best deposit & <br /> withdrawal broker</div>
                      <div className="ai-d-year">Dubai 2024</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div data-aos="fade-up">
              <AwardSlider />
            </div>

          </div>

        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">

          <div className="common-section">

            <div className="common-heading-bx  pb-5 pb-md-0">
              <div className="common-heading">News & <span className="ch-color">Insights</span></div>
              <div className="common-sub-heading">Stay informed with our latest market updates and expert insights, empowering you to <br /> make well-informed decisions in the world of finance.</div>
            </div>

            <div data-aos="fade-up">
              <div className="d-none d-lg-flex row news-card-row nci-style-three ncr-animated mt-4">

                <div className="px-0 col-lg-3">
                  <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html" rel="noreferrer" target="_blank" className="news-card-item">
                    <img className="nci-img" src={client1} alt="" />
                  </a>
                </div>
                <div className="px-0 col-lg-3">
                  <a href="https://finance.yahoo.com/news/winprofx-announces-milestone-commitment-online-063100151.html" rel="noreferrer" target="_blank" className="news-card-item">
                    <img className="nci-img" src={client2} alt="" />
                  </a>
                </div>
                <div className="px-0 col-lg-3">
                  <a href="https://www.digitaljournal.com/pr/news/newsfile/winprofx-announces-milestone-in-commitment-to-online-trading-excellence" rel="noreferrer" target="_blank" className="news-card-item">
                    <img className="nci-img" src={client6} alt="" />
                  </a>
                </div>
                <div className="px-0 col-lg-3">
                  <a href="https://www.thewhig.com/newsfile/189715-winprofx-announces-milestone-in-commitment-to-online-trading-excellence" rel="noreferrer" target="_blank" className="news-card-item">
                    <img className="nci-img" src={client3} alt="" />
                  </a>
                </div>

                {/* <div className="px-0 col-lg-3">
                  <a href="https://finance.yahoo.com/news/winprofx-announces-milestone-commitment-online-063100151.html"  rel="noreferrer" target="_blank" className="news-card-item">
                    <div className="nci-tag">yahoo!finance</div>
                    <div className="nci-heading">Winprofx Announces Milestone in Commitment to Online Trading Excellence</div>
                    <div className="nci-read-time">1 minutes to read</div>
                    <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                    <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                    <div className="nci-date">December 5, 2023</div>
                  </a>
                </div>

                <div className="px-0 col-lg-3">
                  <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html"  rel="noreferrer" target="_blank" className="news-card-item">
                    <div className="nci-tag">Street Insider</div>
                    <div className="nci-heading">WinproFX Announces Milestone in Commitment to Online Trading Excellence</div>
                    <div className="nci-read-time">1 minutes to read</div>
                    <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                    <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                    <div className="nci-date">December 5, 2023</div>
                  </a>
                </div>

                <div className="px-0 col-lg-3">
                  <a href="https://www.digitaljournal.com/pr/news/newsfile/winprofx-announces-milestone-in-commitment-to-online-trading-excellence"  rel="noreferrer" target="_blank" className="news-card-item">
                    <div className="nci-tag">Digital Journal</div>
                    <div className="nci-heading">WinproFX Announces Milestone in Commitment to Online Trading Excellence</div>
                    <div className="nci-read-time">1 minutes to read</div>
                    <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                    <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                    <div className="nci-date">December 5, 2023</div>
                  </a>
                </div>

                <div className="px-0 col-lg-3">
                  <a href="https://www.thewhig.com/newsfile/189715-winprofx-announces-milestone-in-commitment-to-online-trading-excellence"  rel="noreferrer" target="_blank" className="news-card-item">
                    <div className="nci-tag">Thewhig</div>
                    <div className="nci-heading">WinproFX Announces Milestone in Commitment to Online Trading Excellence</div>
                    <div className="nci-read-time">1 minutes to read</div>
                    <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                    <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                    <div className="nci-date">December 5, 2023</div>
                  </a>
                </div> */}


              </div>
            </div>

            <div className="d-block d-lg-none">
              <NewsInsightsSlider />
            </div>

            <Link to="/news" className="common-btn-item cbi-outline mx-auto mt-4" cbidata={"Show more"}><span>Show more<img src={Img.arrowright} alt="" /></span></Link>

          </div>

        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">
            <Commonsection />
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Embark on a transformative trading journey with us — <br /> where <span className="tlm-h-color">trading isn't just an action, it's a difference.</span> Trade <br /> with us, be the difference.</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Dive into a world where every trade creates a ripple of impact. Join us in shaping a unique trading experience that not only sets you apart but also makes a positive difference. Trade with purpose, trade with us.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
              {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Start your journey now</span></div> */}
              <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Start your journey now</span></a>
              {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Start your journey now</span></div> */}
            </div>
          </div>
        </div>
      </section>

    </>
  );
};
