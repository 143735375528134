import React, { useMemo, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet-async";
import CommonHelmet from '../../components/CommonHelmet';

// Phone Number Contry Code
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import Swal from 'sweetalert2';
import axios from 'axios';
import API_HOST from '../../config/config';
import countries from '../../config/country';
import * as Img from '../../components/Img';

import Select from "react-select";
import countryList from 'react-select-country-list'

// Images Common File
// import * as Img from '../../components/Img';
import editpage from '../../img/icons/edit-page.png';

const MOBILE_REGEX = /^[0-9]{10}$/; 
const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default function PageJoinOurTeam() {
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])
  
    // const changeHandler = value => {
    //   setValue(value)
    // }

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
      console.log(event.target.files[0]);
    };

    const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const [contactFormButton, setContactFormButton] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState('');
  const handleOnChange = (value) => {
    // Update state or perform other actions when the phone number changes
    setPhoneNumber(value);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const nameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const countryRef = useRef();
  const messageRef = useRef();
  

  const [name, setName] = useState('');
  const [validName, setValidName] = useState(true);
  const [nameMsg, setNameMsg] = useState('');

  const [lastName, setLastName] = useState('');
  const [validLastName, setValidLastName] = useState(true);
  const [lastNameMsg, setLastNameMsg] = useState('');

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [emailMsg, setEmailMsg] = useState('');

  const [country, setCountry] = useState('');
  const [validCountry, setValidCountry] = useState(true);
  const [countryMsg, setCountryMsg] = useState('');

  const [phoneValue, setPhoneValue] = useState('');
  const [phoneMsg, setPhoneMsg] = useState('');
  const [isValid, setIsValid] = useState(true);

  const [message, setMessage] = useState('');
  const [validMessage, setValidMessage] = useState(true);
  const [messageMsg, setMessageMsg] = useState('');

  const handleInputChange = (val) => {
    setPhoneValue(val || '');
    setIsValid((!!val && isValidPhoneNumber((val || ''))));
    if(val == '')
    {
        setPhoneMsg('Phone Number is required');
    }
    else if((!!val && isValidPhoneNumber((val || ''))) == false)
    {
        setPhoneMsg('Please enter a valid number.');
    }
    else if(val != '' && ((!!val && isValidPhoneNumber((val || ''))) == true))
    {
        setPhoneMsg('');
    }    
};

const phoneNumberCheck = () => {
    setIsValid((!!phoneValue && isValidPhoneNumber(phoneValue || '')));
    if(phoneValue == '')
    {
        setPhoneMsg('Phone Number is required');
    }
    else if((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == false)
    {
        setPhoneMsg('Please enter a valid number.');
    }
    else if(phoneValue != '' && ((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == true))
    {
        setPhoneMsg('');
    }
    else
    {
        setPhoneMsg('');
    }
    return (!!phoneValue && isValidPhoneNumber((phoneValue || '')));
}



const [ogImageUrl , setOgImageUrl] = useState('https://adstars.pmcommu.in/public/adstar.jpg');

useEffect(() => {},[name,lastName,email,phoneValue,message]);

function checkName(value)
{
    setName(value);
    if(value != ""){ setValidName(true); }else{ setValidName(false); }
}

function checkLastName(value)
{
    setLastName(value);
    if(value != ""){ setValidLastName(true); }else{ setValidLastName(false); }
}

function checkCountry(value)
{
    console.log(value);
    setCountry(value);
    if(value != ""){ setValidCountry(true); }else{ setValidCountry(false); }
}

function checkEmail(value)
{
    setEmail(value);
    if(value != ""){
        const email_result = EMAIL_REGEX.test(value);
        setValidEmail(email_result);
    }else{ 
        setValidEmail(false); 
    }
} 

function checkMessage(value)
{
    setMessage(value);
    if(value != ""){ setValidMessage(true); }else{ setValidMessage(false); }
}

function fundFormSubmitCheck () {
    var checkVal = [];
    
    if(name != ""){ checkVal = [...checkVal,true]; setValidName(true); }else{ checkVal = [...checkVal,false]; setValidName(false); }
    if(lastName != ""){ checkVal = [...checkVal,true]; setValidLastName(true); }else{ checkVal = [...checkVal,false]; setValidLastName(false); }
    if(country != ""){ setValidCountry(true); checkVal = [...checkVal,true]; }else{ setValidCountry(false); checkVal = [...checkVal,false]; }  
    if(message != ""){ checkVal = [...checkVal,true]; setValidMessage(true); }else{ checkVal = [...checkVal,false]; setValidMessage(false); }         
    if(phoneValue != ""){
        const phone_result = phoneNumberCheck();
        setIsValid(phone_result);
        checkVal = [...checkVal,phone_result];
    }else{ 
        setIsValid(false); 
        checkVal = [...checkVal,false];
    }

    if(email != ""){
        const email_result = EMAIL_REGEX.test(email);
        setValidEmail(email_result);
        checkVal = [...checkVal,email_result];
    }else{ 
        setValidEmail(false);
        checkVal = [...checkVal,false];
    }
       
    if(checkVal.includes(false)) { return false; } else { return true; }
}

const handleJoinFormSubmit = async (e) => {
    e.preventDefault();        
    if(!fundFormSubmitCheck())
    {
        setErrMsg("");
        return;
    }
    else{
        setErrMsg('');
        setValidName(true);
        // setValidCity(true);
        setValidLastName(true);
        setValidCountry(true);
        setIsValid(true);
        setValidEmail(true);
        setValidMessage(true);
    }

    try {
        setContactFormButton(false);
        var phoneValueGet = '';
        if((isValidPhoneNumber((phoneValue || ''))) == true)
        {
            const phoneNumber = parsePhoneNumber(phoneValue);
            if (phoneNumber) {
                phoneValueGet = `${phoneNumber.number}`;
            }
        }
        const formData = new FormData();
        formData.append('name', name);
        formData.append('last_name', lastName);
        formData.append('phone', phoneValueGet);
        formData.append('email', email);
        formData.append('country', country.label);
        formData.append('message', message);
        formData.append('file', selectedFile);
        const response = await axios.post(API_HOST+'/xhr/save-join-request.php',  
            formData
        ,{headers: {
            "Content-Type": "multipart/form-data",
            withCredentials: true,
        }},)
        .then(response => {
            if(response.data.error == '0' || response.data.error == 0)
            {
                setErrMsg('');
                setName('');
                setValidName(true);
                setNameMsg('');
                setLastName('');
                setValidLastName(true);
                setLastNameMsg('');
                setCountry('');
                setValidCountry(true);
                setCountryMsg('');  
                setEmail('');
                setValidEmail(true);
                setEmailMsg('');
                setPhoneValue('');
                setIsValid(true);
                setPhoneMsg('');
                setMessage('');
                setValidMessage(true);
                setMessageMsg('');
                setContactFormButton(true);
                Toast.fire({
                  icon: 'success',
                  title: `${response.data.msg}`
                });
                //$('#GetFundedModal').modal('hide');
            }                
        })
        .catch(error => {
            if(!error?.response)
            {
                Toast.fire({
                    icon: 'error',
                    title: "No Server Response."
                });
            }
            else if(error.response.data.error == 1)
            {
                if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                {   
                    if('name' in error.response.data.data)
                    {
                        setNameMsg(error.response.data.data.name.toString());
                        setValidName(false);
                    }

                    if('lastName' in error.response.data.data)
                    {
                        setLastNameMsg(error.response.data.data.lastName.toString());
                        setValidLastName(false);
                    }

                    if('phone' in error.response.data.data)
                    {
                        setPhoneMsg(error.response.data.data.phone.toString());
                        setIsValid(false);
                    }

                    if('email' in error.response.data.data)
                    {
                        setEmailMsg(error.response.data.data.email.toString());
                        setValidEmail(false);
                    }

                    if('message' in error.response.data.data)
                    {
                        setMessageMsg(error.response.data.data.message.toString());
                        setValidMessage(false);
                    }
                }  
                Toast.fire({
                    icon: 'error',
                    title: "contact us not send."
                });                  
            }
            else if(error.response?.status === 409 )
            {
                Toast.fire({
                    icon: 'error',
                    title: "contact us not send."
                });
            }
            else{
                Toast.fire({
                    icon: 'error',
                    title: "contact us not send."
                });
            }
            setContactFormButton(true);
        });
        setContactFormButton(true);
    } catch (err) {
        setContactFormButton(true);
        console.error('Error fetching data catch:', err);
    }
}
  return (

    <>

        {/*  <Helmet>
            <title>Join Our Team | WinproFX</title>
        </Helmet> */}
        <CommonHelmet title="Join Our Team | WinproFX" />

        <section className="relative">
            <div className="container-lg cl-custome">
            <div className="textlayout-main-bx pb-3">
                <div className="breadcrumb-bx">
                    <Link className="breadcrumb-link" to="/">Home</Link>
                    <div className="breadcrumb-link breadcrumb-active">/ Join Our Team</div>
                </div>
                <div className="tlm-heading">Join Our Team</div>
            </div>
            </div>
        </section>

        <div className="py-2"></div>

        <section className="">
            <div className="container-lg cl-custome py-5">
                <div className="common-section">
        

                    <form onSubmit={handleJoinFormSubmit} className="" style={{maxWidth:"768px",width:"100%",margin:"auto"}}>
                 
                        <div className="row">
                            
                            <div className="col-6">
                              <div className={`signinup-group ${name=='' && !validName ? "error-group" : ""} `}>
                                  <div className="group__label">First name</div>
                                  <input type="text" ref={nameRef} placeholder="Enter first name here.." onChange={(e) => checkName(e.target.value)} aria-invalid={validName ? "false" : "true"} aria-describedby="name_error" />
                                  <span id="name_error" className={`form-error-msg mb-1 ${name=='' && !validName ? "" : "d-none"} `}>{nameMsg != '' ? nameMsg : 'Please enter firstname'}</span>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className={`signinup-group ${lastName=='' && !validLastName ? "error-group" : ""} `}>
                                  <div className="group__label">Last Name</div>
                                  <input type="text" placeholder="Enter last name here.." ref={lastNameRef} onChange={(e) => checkLastName(e.target.value)} aria-invalid={validLastName ? "false" : "true"} aria-describedby="last_name_error"/>
                                  <span id="last_name_error" className={`form-error-msg mb-1 ${lastName=='' && !validLastName ? "" : "d-none"} `}>{lastNameMsg != '' ? lastNameMsg : 'Please enter lastname'}</span>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className={`signinup-group sg-country-code ${phoneValue=='' && !isValid ? "error-group" : ""} `}>
                                  <div className="group__label">Phone No.</div>
                                  <PhoneInput 
                                  // international 
                                  countryCallingCodeEditable={false} defaultCountry="AE"  placeholder="Enter phone number" id="phone" ref={phoneRef} value={phoneValue} onChange={handleInputChange}/>
                                  {/* <input type="number" placeholder="Enter your Phone no. here"/>
                                  <span className="group_left_icon">+1</span> */}
                                  <span id="phone_error" className={`form-error-msg mb-1 ${!isValid ? "" : "d-none"} `}>{phoneMsg != '' ? phoneMsg : 'Please enter valid number'}</span>
                              </div>      
                            </div>

                            <div className="col-sm-6">
                            <div className={`signinup-group ${email=='' && !validEmail ? "error-group" : ""} `}>
                                <label className="group__label">Email</label>
                                <input type="email" id="email" ref={emailRef} autoComplete="off" onChange={(e) => checkEmail(e.target.value)} aria-invalid={validEmail ? "false" : "true"} aria-describedby="email_error" placeholder="Enter your email here.." value={email} />
                                <span id="email_error" className={`form-error-msg mb-1 ${!validEmail ? "" : "d-none"} `}>{emailMsg != '' ? emailMsg : 'Please enter valid email'}</span>
                            </div>
                            </div>

                            <div className="col-sm-6">
                                <div className={`signinup-group sg-select-country`}>
                                    <label className="group__label">Select Country</label>
                                    <Select options={options} value={country} onChange={checkCountry} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className={`signinup-group`}>
                                <label className="group__label mb-2">Upload Document</label>
                                    <label htmlFor="fileupload" className="fileupload">
                                        <img className="feud-img" src={editpage} alt="" />
                                        <div className="feud-lable">Upload Your CV <span>Browse</span></div>
                                    </label>
                                    <input hidden type="file" name="fileupload" id="fileupload" accept=".pdf,.doc,.docx" onChange={handleFileChange}/>
                                </div>

                                <ol className="fileupload-list">
                                    
                                   {  selectedFile &&   
                                        <li className="fileupload-item filstn  mt-0 mb-4">
                                          
                                            <span>
                                               {selectedFile.name}
                                                <span className="fileupload-play">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                    </svg>
                                                </span>
                                            </span>
                                            
                                            <span className="clear-input-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"></path>
                                                </svg>
                                            </span>
                                        </li>
                                    }
                                </ol>
                            </div>

                            <div className="col-12">
                                <div className={`signinup-group ${message=='' && !validMessage ? "error-group" : ""} `}>
                                    <label className="group__label">Message</label>
                                    <textarea id="message" ref={messageRef} onChange={(e) => checkMessage(e.target.value)} aria-invalid={validMessage ? "false" : "true"} aria-describedby="message_error" placeholder="Enter your message here.." value={message} ></textarea>
                                    {/* <span className="twl-lable">0/250 word</span> */}
                                    <span id="message_error" className={`form-error-msg mb-1 ${message=='' && !validMessage ? "" : "d-none"} `}>{messageMsg != '' ? messageMsg : 'Please enter valid message'}</span>
                                </div>
                            </div>

                        </div>

                        <button className="common-btn-item cbi-fill mt-3 mx-auto"> <span>Submit</span></button>
                        {/* <button type="button" className="common-btn-item cbi-fill mt-3 lsb-loader-btn">
                            <img src={Img.loading} alt="" />Please wait ....
                        </button> */}
                         {/* <button className={`common-btn-item cbi-fill mt-3 mx-auto ${contactFormButton ? '' : 'd-none'} `}><span>Submit</span></button> */}
                          <button type="button" className={`common-btn-item cbi-fill mt-3 lsb-loader-btn ${contactFormButton ? 'd-none' : ''} `}>
                            <img src={Img.loading} alt="" />Please wait ....
                          </button>
                    </form>

                </div>
            </div>   
        </section>

    </>
  );
};
