
import React from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";

// Images Common File
import * as Img from '../Img';

// Css File
import '../../css/sidemenu.css'

// Design Files
import { useMenu } from './MenuContext';
import Socialicon from '../Socialicon';

export default function Sidemenu() {

    const { isActive, toggleMenu } = useMenu();

    // -----
    const location = useLocation();

    let weblights = '';

    if (location.pathname === '/introducing-broker' && document.body.classList.contains('active-dark')) {
        weblights = '';
    } else if (location.pathname === '/introducing-broker'){
        weblights = 'active';
    };
    // -----

    return (
        <>
            <aside className={`side-menu-bx ${weblights ? 'side-white' : ''} ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
                <div className="smb-logo-bx">
                    <span style={{top:"17px"}} className="close-icon trigger-closed">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
                    </span>
                    <Link to="/" className="smb-logo-link">
                        <img className="logo-img" src={weblights ? Img.logo_dark : Img.logo_light} alt="" />
                    </Link>
                </div>

                <div className="smb-menu-bx">

                    <div className="smb-signinup-btn mt-2">
                        <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className={` ${weblights ? 'cbi-white' : ''} common-btn-item cbi-outline `}><span>Register</span></a>
                        <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className={` ${weblights ? 'cbi-white' : ''} common-btn-item cbi-fill`}><span>Login</span></a>
                        
                        {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className={` ${weblights ? 'cbi-white' : ''} common-btn-item cbi-outline `}><span>Register</span></div> */}
                        {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className={` ${weblights ? 'cbi-white' : ''} common-btn-item cbi-fill`}><span>Login</span></div> */}
                    </div>
                
                    <ul className='pb-0 mt-3'>
                        <li><NavLink to="/introducing-broker" className="smb-link smb-item-highlight">Introducing Broker</NavLink></li>
                    </ul>

                    <ul className='pb-0 mt-3'>
                        <div className="smb-nav-lable mt-3 mb-2">Markets</div>
                        <li><NavLink to="/forex" className="smb-link">Forex</NavLink></li>
                        <li><NavLink to="/indices" className="smb-link">Indices</NavLink></li>
                        <li><NavLink to="/crypto" className="smb-link">Crypto</NavLink></li>
                        <li><NavLink to="/metals" className="smb-link">Metals</NavLink></li>
                        <li><NavLink to="/commodities" className="smb-link">Commodities</NavLink></li>
                        <li><NavLink to="/Oil" className="smb-link">Oil</NavLink></li>
                    </ul>

                    <ul className='pb-0 mt-4'>
                        <div className="smb-nav-lable mt-3 mb-2">Explore</div>
                        <li><NavLink to="/" className="smb-link">Home</NavLink></li>
                        <li><NavLink to="/platform" className="smb-link">Platform</NavLink></li>
                        <li><NavLink to="/about" className="smb-link">About Us</NavLink></li>
                        <li><NavLink to="/free-funded-accounts" className="smb-link">Free Funded accounts</NavLink></li>
                        {/* <li><div data-bs-target="#GlobalRegulationsModal" data-bs-toggle="modal" className="smb-link">Global Regulations</div></li> */}
                        <li><NavLink to="/education" className="smb-link">Education</NavLink></li>
                        <li><NavLink to="/news" className="smb-link">News & Insights</NavLink></li>
                        <li><NavLink to="/join-our-team" className="smb-link">Join Our Team</NavLink></li>
                        <li><NavLink to="/contact" className="smb-link">Contact Us</NavLink></li>
                        <li><NavLink to="/sitemap" className="smb-link">Sitemap</NavLink></li>
                    </ul>
                    
                    {/* <ul className='pb-0 mt-4'>
                        <div className="smb-nav-lable mt-3 mb-2">Tools</div>
                        <li><NavLink to="/analytical-tools" className="smb-link">Analytical tools</NavLink></li>
                        <li><NavLink to="/economic-calendar" className="smb-link">Economic calendar</NavLink></li>
                        <li><NavLink to="/currency-calculator" className="smb-link">Currency calculator</NavLink></li>
                        <li><NavLink to="/currency-converter" className="smb-link">Currency converter</NavLink></li>
                    </ul> */}

                    <ul className='pb-0 mt-4'>
                        <div className="smb-nav-lable mt-3 mb-2">Our Policies</div>
                        <li><NavLink to="/privacy-policy" className="smb-link">Privacy Policy</NavLink></li>
                        <li><NavLink to="/terms-and-conditions" className="smb-link">Terms & Conditions</NavLink></li>
                        <li><NavLink to="/aml-policy" className="smb-link">AML Policy</NavLink></li>
                        <li><NavLink to="/deposit-withdrawal-policy" className="smb-link">Deposit & withdrawal Policy</NavLink></li>
                        <li><NavLink to="/restricted-countries" className="smb-link">Restricted Countries</NavLink></li>
                        <li><NavLink to="/risk-disclosure" className="smb-link">Risk Disclosure</NavLink></li>
                    </ul>

                    <div className="py-5"></div>

                </div>

                <div className="smb-footer">
                    <Socialicon className={` ${weblights ? 'social-white' : ''} social-icon justify-content-center `} />
                </div>

            </aside>

            <div className={`overlaydiv ${isActive ? 'active' : ''}`} onClick={toggleMenu}></div>
        </>
    );
};



