import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { MenuProvider } from '../components/Header/MenuContext';

//Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../components/Header/Header";

// Home Page Demo
// import PageHomedemo from "../containers/PageHome/PageHomedemo";

// Pages
import PageHome from "../containers/PageHome/PageHome";
import PageForex from "../containers/PageForex/PageForex";
import PageIndices from "../containers/PageIndices/PageIndices";
import PageCrypto from "../containers/PageCrypto/PageCrypto";
import PageMetals from "../containers/PageMetals/PageMetals";
import PageCommodities from "../containers/PageCommodities/PageCommodities";
import PageOil from "../containers/PageOil/PageOil";
import PageFundedAccount from "../containers/PageFundedAccount/PageFundedAccount";
import PagePlatform from "../containers/PagePlatform/PagePlatform";
import PageAbout from "../containers/PageAbout/PageAbout";
import PageIntroducingBroker from "../containers/PageIntroducingBroker/PageIntroducingBroker";
import PageContact from "../containers/PageContact/PageContact";
import PageEducation from "../containers/PageEducation/PageEducation";
import PageNews from "../containers/PageNews/PageNews";
import PageBlog from "../containers/PageBlog/PageBlog";
import PageBlogDetail from "../containers/PageBlog/PageBlogDetail";
import PageBlogDetail2 from "../containers/PageBlog/PageBlogDetail2";
import PageBlogDetail3 from "../containers/PageBlog/PageBlogDetail3";
import PageBlogDetail4 from "../containers/PageBlog/PageBlogDetail4";
import PageBlogDetail5 from "../containers/PageBlog/PageBlogDetail5";
import PageBlogDetail6 from "../containers/PageBlog/PageBlogDetail6";
import PageBlogDetail7 from "../containers/PageBlog/PageBlogDetail7";
import PageBlogDetail8 from "../containers/PageBlog/PageBlogDetail8";
import PageBlogDetail9 from "../containers/PageBlog/PageBlogDetail9";
import PageBlogDetail10 from "../containers/PageBlog/PageBlogDetail10";
import PageBlogDetail11 from "../containers/PageBlog/PageBlogDetail11";
import PageBlogDetail12 from "../containers/PageBlog/PageBlogDetail12";
import PageBlogDetail13 from "../containers/PageBlog/PageBlogDetail13";

//
import PageJoinOurTeam from "../containers/PageJoinOurTeam/PageJoinOurTeam";
import PagePrivacy from "../containers/PagreLagle/PagePrivacy";
import PageTermsAndConditions from "../containers/PagreLagle/PageTermsAndConditions";
import PageAMLPolicy from "../containers/PagreLagle/PageAMLPolicy";
import PageRestrictedCountries from "../containers/PagreLagle/PageRestrictedCountries";
import PageDepositWithdrawalPolicy from "../containers/PagreLagle/PageDepositWithdrawalPolicy";
import PageRiskDisclosure from "../containers/PagreLagle/PageRiskDisclosure";

//
//  import PageAnalyticalTools from "../containers/PageAnalyticalTools/PageAnalyticalTools";
//  import PageEconomicCalendar from "../containers/PageEconomicCalendar/PageEconomicCalendar";
//  import PageTradingCalculator from "../containers/PageTradingCalculator/PageTradingCalculator";
//  import Pagecurrencycalculator from "../containers/PageTradingCalculator/Pagecurrencycalculator";
//  import PageCurrencyConverter from "../containers/PageCurrencyConverter/PageCurrencyConverter";

// Sitemap
import PageSitemap from "../containers/PageSitemap/PageSitemap"

// 
import Page404 from "../containers/PageWebStatus/Page404"

// Thank you
import PageThankYou from "../containers/PageThankYou/PageThankYou"

// Footer section
import Footer from "../components/Footer/Footer";
import Layout from "./Layout";

// 
import SignInBtns from "../components/SignInBtns";
import SignUpBtns from "../components/SignUpBtns";

//
import PageRegister from "../containers/PageRegister/PageRegister";

export const pages = [
  
  // Demo Home Page
  // { path: "/home1", component: PageHomedemo },
  // { path: "/home2", component: PageHomedemo },
  // { path: "/home3", component: PageHomedemo },
  // { path: "/home4", component: PageHomedemo },
  // { path: "/home5", component: PageHomedemo },

  // Home Page
  { path: "/", component: PageHome },
  { path: "/platform", component: PagePlatform },
  { path: "/forex", component: PageForex },
  { path: "/indices", component: PageIndices },
  { path: "/crypto", component: PageCrypto },
  { path: "/metals", component: PageMetals },
  { path: "/commodities", component: PageCommodities },
  { path: "/oil", component: PageOil },
  { path: "/free-funded-accounts", component: PageFundedAccount },
  { path: "/about", component: PageAbout },
  { path: "/introducing-broker", component: PageIntroducingBroker },
  { path: "/contact", component: PageContact },
  { path: "/education", component: PageEducation },
  { path: "/news", component: PageNews },
  { path: "/blog", component: PageBlog },
  { path: "/blog/best-forex-indices-crypto-metals-commodities-and-oil-trading-platform-in-india-winprofx", component: PageBlogDetail },
  { path: "/blog/free-funded-accounts-winprofx", component: PageBlogDetail2 },
  { path: "/blog/best-forex-trading-app-platform-in-india-winprofx", component: PageBlogDetail3 },
  { path: "/blog/best-indices-trading-app-platform-in-india-winprofx", component: PageBlogDetail4 },
  { path: "/blog/best-crypto-trading-app-platform-in-india-winprofx", component: PageBlogDetail5 },
  { path: "/blog/best-commodities-trading-app-platform-in-india-winprofx", component: PageBlogDetail6 },
  { path: "/blog/best-metals-trading-app-platform-in-india-winprofx", component: PageBlogDetail7 },
  { path: "/blog/best-oil-trading-app-platform-in-india-winprofx", component: PageBlogDetail8 },
  { path: "/blog/free-funded-forex-trading-account-winprofx", component: PageBlogDetail9 },
  { path: "/blog/pro-forex-support-trading-winprofx", component: PageBlogDetail10 },
  { path: "/blog/free-funded-forex-account-without-deposit-winprofx", component: PageBlogDetail11 },
  { path: "/blog/funded-forex-accounts-winprofx", component: PageBlogDetail12 },
  { path: "/blog/get-a-funded-trading-account-winprofx", component: PageBlogDetail13 },
  
  //
  { path: "/join-our-team", component: PageJoinOurTeam },
  { path: "/privacy-policy", component: PagePrivacy },
  { path: "/terms-and-conditions", component: PageTermsAndConditions },
  { path: "/aml-policy", component: PageAMLPolicy },
  { path: "/restricted-countries", component: PageRestrictedCountries },
  { path: "/deposit-withdrawal-policy", component: PageDepositWithdrawalPolicy },
  { path: "/risk-disclosure", component: PageRiskDisclosure },
  
  //
  //  { path: "/analytical-tools", component: PageAnalyticalTools },
  //  { path: "/economic-calendar", component: PageEconomicCalendar },
  //  { path: "/trading-calculator", component: PageTradingCalculator },
  //  { path: "/currency-calculator", component: Pagecurrencycalculator },
  //  { path: "/currency-converter", component: PageCurrencyConverter },

  //
  { path: "/sitemap", component: PageSitemap },

  //
  { path: "/thankyou", component: PageThankYou },

  //
  { path: "/register", component: PageRegister },

];

const MyRoutes = () => {

  const location = useLocation();
  const currentPath = location.pathname;

  React.useEffect(() => {
    if (currentPath === '/introducing-broker') {
      document.body.classList.add('lighttheme');
    } else {
      document.body.classList.remove('lighttheme');
    }
  }, [currentPath]);

  const isKnownRoute = pages.some(page => page.path === currentPath);
  const hideHeaderFooter = !isKnownRoute || currentPath === '/thankyou';
  const hideSection = !isKnownRoute || currentPath === '/register';

  return (
    <>
      <Layout>
        <ScrollToTop />
        <Loader />
        {!hideHeaderFooter && <Header hideSection={!hideSection} /> }
        <main className="main-wrapper">
          <Routes>
            {pages.map(({ component: Component, path }, index) => {
              return <Route key={index} element={<Component />} path={path} />;
            })}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </main>
        <SignInBtns />
        <SignUpBtns />
        {!hideHeaderFooter && <Footer hideSection={!hideSection} /> }
      </Layout>
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
      <MenuProvider>
        <MyRoutes />
      </MenuProvider>
    </Router>
  );
}

export default MainRoutes;