import React from 'react';
import { Link } from 'react-router-dom';
import CommonHelmet from '../../components/CommonHelmet';

import blog1 from "../../img/blog/blog-thumb(1).jpg"
import blog2 from "../../img/blog/blog-thumb(2).jpg"
import blog3 from "../../img/blog/blog(3).jpg"
import blog4 from "../../img/blog/blog(4).jpg"
import blog5 from "../../img/blog/blog(5).jpg"
import blog6 from "../../img/blog/blog-thumb(6).jpg"
import blog7 from "../../img/blog/blog(7).jpg"
import blog8 from "../../img/blog/blog(8).jpg"
import blog9 from "../../img/blog/blog-thumb(9).jpg"
import blog10 from "../../img/blog/blog(10).jpg"
import blog11 from "../../img/blog/blog-thumb(11).jpg"
import blog12 from "../../img/blog/blog-thumb(12).jpg"
import blog13 from "../../img/blog/blog-thumb(13).jpg"

export default function PageBlog() {
    const blogs = [
        {
            slug: 'free-funded-forex-trading-account-winprofx',
            title: 'Free funded forex trading account : WinproFX',
            thumbnail: blog9
        },
        {
            slug: 'pro-forex-support-trading-winprofx',
            title: 'Pro Forex Support Trading: WinProFX',
            thumbnail: blog10
        },
        {
            slug: 'free-funded-forex-account-without-deposit-winprofx',
            title: 'Free Funded Forex Account Without Deposit: WinProFX',
            thumbnail: blog11
        },
        {
            slug: 'funded-forex-accounts-winprofx',
            title: 'Funded Forex Accounts: WinProFX',
            thumbnail: blog12
        },
        {
            slug: 'get-a-funded-trading-account-winprofx',
            title: 'Get a Funded Trading Account : WinproFX',
            thumbnail: blog13
        },
        {
            slug: 'best-forex-indices-crypto-metals-commodities-and-oil-trading-platform-in-india-winprofx',
            title: 'Best Forex, Indices, Crypto, Metals, Commodities & Oil Trading Platform in India : WinproFX',
            thumbnail: blog1
        },    
        {
            slug: 'free-funded-accounts-winprofx',
            title: 'Free Funded Accounts: WinproFX',
            thumbnail: blog2
        },
        {
            slug: 'best-forex-trading-app-platform-in-india-winprofx',
            title: 'Best Forex Trading App, Platform in India: WinProFX',
            thumbnail: blog3
        },
        {
            slug: 'best-indices-trading-app-platform-in-india-winprofx',
            title: 'Best Indices Trading App, Platform in India: WinProFX',
            thumbnail: blog4
        },
        {
            slug: 'best-crypto-trading-app-platform-in-india-winprofx',
            title: 'Best Crypto Trading App, Platform in India: WinProFX',
            thumbnail: blog5
        },
        {
            slug: 'best-commodities-trading-app-platform-in-india-winprofx',
            title: 'Best Commodities Trading App, Platform in India: WinProFX',
            thumbnail: blog6
        },
        {
            slug: 'best-metals-trading-app-platform-in-india-winprofx',
            title: 'Best Metals Trading App, Platform in India: WinProFX',
            thumbnail: blog7
        },
        {
            slug: 'best-oil-trading-app-platform-in-india-winprofx',
            title: 'Best Oil Trading App, Platform in India: WinProFX',
            thumbnail: blog8
        }
    ];
    return (
        <>
            <CommonHelmet 
                title="Free Funded Accounts with WinproFX: Best Forex, Indices, Crypto, Commodities, Metals, and Oil Trading App and Platform in India"
                description="Get Free Funded Accounts with WinproFX, India's best trading platform for Forex, Indices, Crypto, Commodities, Metals, and Oil. Trade with confidence using our user-friendly app, designed for top-tier performance and unmatched reliability."
                keywords="Free funded accounts, best forex trading app India, best indices trading app India, best crypto trading app India, commodities trading platform India, metals trading app India, oil trading platform India, forex platform India, trading app"
            />

            <div className="d-none" style={{display:"none"}}>
                <h1>Free Funded Accounts with WinproFX: Best Forex, Indices, Crypto, Commodities, Metals, and Oil Trading App and Platform in India</h1>
                <h2>Get Free Funded Accounts with WinproFX, India's best trading platform for Forex, Indices, Crypto, Commodities, Metals, and Oil. Trade with confidence using our user-friendly app, designed for top-tier performance and unmatched reliability.</h2>
                <b>Free funded accounts</b>
                <b>best forex trading app India</b>
                <b>best indices trading app India</b>
                <b>best crypto trading app India</b>
                <b>commodities trading platform India</b>
                <b>metals trading app India</b>
                <b>oil trading platform India</b>
                <b>forex platform India</b>
                <b>trading app</b>
            </div>

            <section className="relative">
                <div className="container-lg cl-custome">
                    <div data-aos="fade-up" className="textlayout-main-bx">
                        <div className="breadcrumb-bx">
                            <Link className="breadcrumb-link" to="/">Home</Link>
                            <div className="breadcrumb-link breadcrumb-active">/ Blog</div>
                        </div>
                        <div className="tlm-heading">Blog</div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg cl-custome">
                    <div className="common-section">
                        <div className="row">
                            {blogs.map((blog, index) => (
                                <div data-aos="fade-up" className="col-xl-3 col-md-4 col-sm-6 mb-5" key={index}>
                                    <Link to={`/blog/${blog.slug}`} className="blog-item">
                                        <div className="bi-img">
                                            <img src={blog.thumbnail} alt={blog.title} />
                                        </div>
                                        <div className="bi-heading">{blog.title}</div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <div className="py-2"></div>
        </>
    );
}
