import React from 'react';

// Slider Code
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import award from "../img/award/award.webp";
import award2 from "../img/award/award2.webp";
import tropyleaf from "../img/icons/tropy-leaf.png";

function AwardItem({ tropyleaf, award, heading, year }) {
  return (
    <div className="award-item-outer">
      <div className="award-section">
        <div className="as-left">
          <div className="as-l-img">
            <img src={tropyleaf} alt='' />

            <svg className="asl-svg-vert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 1000" preserveAspectRatio="none">
              <path d="M6,0H0v1000h100L6,0z"></path>
            </svg>

            <svg className="asl-svg-hori" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
              <path d="M0,6V0h1000v100L0,6z"></path>
            </svg>
          </div>
        </div>
        <div className="as-right">
          <div className="award-item">
            <div className="award-item">
              <div  className="ai-img">
                <img src={award} alt="" />
              </div>
              <div className="ai-data">
                <div className="ai-d-heading">{heading}</div>
                <div className="ai-d-year">{year}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function AwardSlider() {
  const awardslider = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    className: "slidersidebox award-slider center arrowtwo mt-4 pb-3",
    centerMode: true,
    speed: 500,
    autoplaySpeed: 3000,
    centerPadding: "5%",
    responsive: [
        {
          breakpoint: 576,
          settings: {
            centerMode: false,
          }
        }
      ]
  };

  return (
    <Slider {...awardslider}>
      <AwardItem 
        tropyleaf={tropyleaf}
        award={award}
        heading={<>Best deposit & <br /> withdrawal broker</>}
        year="Dubai 2024"
      />
      <AwardItem 
        tropyleaf={tropyleaf}
        award={award2}
        heading={<>Best regulated <br /> broker</>}
        year="Abu Dhabi 2024"
      />
    </Slider>
  );
}
