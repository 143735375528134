import React, { useState, useEffect, useRef } from 'react';
import {Link} from "react-router-dom";
import CommonHelmet from '../../components/CommonHelmet';

// Phone Number Contry Code
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

//
import FlipBook from './FlipBook'

// Lightgellery files
// import LightGallery from 'lightgallery/react';
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

// import styles
// import 'lightgallery/css/lightgallery.css';
// import 'lightgallery/css/lg-zoom.css';
// import 'lightgallery/css/lg-thumbnail.css';

// Images Common File
// import * as Img from '../../components/Img';
// import world from '../../img/bgimg/world.svg';
import laptop from '../../img/bgimg/laptop.webp';
import mobile from '../../img/bgimg/mobile.webp';

import ellipse3 from '../../img/Ellipse(3).png';

// import video1 from '../../img/video.mp4'
import videoImg1 from '../../img/video.webp';

// Winpro-Funded-Brochure
import brochurePDF from '../../img/brochure/free-funded-accounts.pdf';
import brochureposter from '../../img/brochure/Untitled.webp';

// import brochureImg from '../../img/brochure/brochure-thumbs/Untitled.jpg';
// import brochureImg2 from '../../img/brochure/brochure-thumbs/Untitled2.jpg';
// import brochureImg3 from '../../img/brochure/brochure-thumbs/Untitled3.jpg';
// import brochureImg4 from '../../img/brochure/brochure-thumbs/Untitled4.jpg';
// import brochureImg5 from '../../img/brochure/brochure-thumbs/Untitled5.jpg';

import Swal from 'sweetalert2';
import axios from 'axios';
import API_HOST from '../../config/config';
import countries from '../../config/country';
import * as Img from '../../components/Img';

const MOBILE_REGEX = /^[0-9]{10}$/;
const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


const downloadSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
    </svg>
  )
}
const EyeSvg = () => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  </svg>
  )
}


export default function PageFundedAccount() {
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const [contactFormButton, setContactFormButton] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState('');
  const handleOnChange = (value) => {
    // Update state or perform other actions when the phone number changes
    setPhoneNumber(value);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const nameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const countryRef = useRef();
  const cityRef = useRef();
  const brokerRef = useRef();
  const equityRef = useRef();
  

  const [name, setName] = useState('');
  const [validName, setValidName] = useState(true);
  const [nameMsg, setNameMsg] = useState('');

  const [lastName, setLastName] = useState('');
  const [validLastName, setValidLastName] = useState(true);
  const [lastNameMsg, setLastNameMsg] = useState('');

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [emailMsg, setEmailMsg] = useState('');

  const [country, setCountry] = useState('');
  const [validCountry, setValidCountry] = useState(true);
  const [countryMsg, setCountryMsg] = useState('');

  const [city, setCity] = useState('');
  const [validCity, setValidCity] = useState(true);
  const [cityMsg, setCityMsg] = useState('');
  
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneMsg, setPhoneMsg] = useState('');
  const [isValid, setIsValid] = useState(true);

const handleInputChange = (val) => {
    setPhoneValue(val || '');
    setIsValid((!!val && isValidPhoneNumber((val || ''))));
    if(val == '')
    {
        setPhoneMsg('Phone Number is required');
    }
    else if((!!val && isValidPhoneNumber((val || ''))) == false)
    {
        setPhoneMsg('Please enter a valid number.');
    }
    else if(val != '' && ((!!val && isValidPhoneNumber((val || ''))) == true))
    {
        setPhoneMsg('');
    }    
};

const phoneNumberCheck = () => {
    setIsValid((!!phoneValue && isValidPhoneNumber(phoneValue || '')));
    if(phoneValue == '')
    {
        setPhoneMsg('Phone Number is required');
    }
    else if((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == false)
    {
        setPhoneMsg('Please enter a valid number.');
    }
    else if(phoneValue != '' && ((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == true))
    {
        setPhoneMsg('');
    }
    else
    {
        setPhoneMsg('');
    }
    return (!!phoneValue && isValidPhoneNumber((phoneValue || '')));
}

const [broker, setBroker] = useState('');
const [validBroker, setValidBroker] = useState(true);
const [brokerMsg, setBrokerMsg] = useState('');

const [equity, setEquity] = useState('');
const [validEquity, setValidEquity] = useState(true);
const [equityMsg, setEquityMsg] = useState('');



useEffect(() => {},[name,lastName,email,phoneValue,country,broker]);

function checkName(value)
{
    setName(value);
    if(value != ""){ setValidName(true); }else{ setValidName(false); }
}

function checkLastName(value)
{
    setLastName(value);
    if(value != ""){ setValidLastName(true); }else{ setValidLastName(false); }
}

function checkCountry(value)
{
    setCountry(value);
    if(value != ""){ setValidCountry(true); }else{ setValidCountry(false); }
}

function checkCity(value)
{
    setCity(value);
    if(value != ""){ setValidCity(true); }else{ setValidCity(false); }
}

function checkEmail(value)
{
    setEmail(value);
    if(value != ""){
        const email_result = EMAIL_REGEX.test(value);
        setValidEmail(email_result);
    }else{ 
        setValidEmail(false); 
    }
} 

function checkBroker(value)
{
    setBroker(value);
    if(value != ""){ setValidBroker(true); }else{ setValidBroker(false); }
}

function checkEquity(value)
{
    setEquity(value);
    if(value != ""){ setValidEquity(true); }else{ setValidEquity(false); }
}

function fundFormSubmitCheck () {
    var checkVal = [];
    
    if(name != ""){ checkVal = [...checkVal,true]; setValidName(true); }else{ checkVal = [...checkVal,false]; setValidName(false); }
    if(lastName != ""){ checkVal = [...checkVal,true]; setValidLastName(true); }else{ checkVal = [...checkVal,false]; setValidLastName(false); }
    if(country != ""){ setValidCountry(true); checkVal = [...checkVal,true]; }else{ setValidCountry(false); checkVal = [...checkVal,false]; }  
    if(city != ""){ checkVal = [...checkVal,true]; setValidCity(true); }else{ checkVal = [...checkVal,false]; setValidCity(false); }
    if(broker != ""){ checkVal = [...checkVal,true]; setValidBroker(true); }else{ checkVal = [...checkVal,false]; setValidBroker(false); }   
    if(equity != ""){ checkVal = [...checkVal,true]; setValidEquity(true); }else{ checkVal = [...checkVal,false]; setValidEquity(false); }          
    if(phoneValue != ""){
        const phone_result = phoneNumberCheck();
        setIsValid(phone_result);
        checkVal = [...checkVal,phone_result];
    }else{ 
        setIsValid(false); 
        checkVal = [...checkVal,false];
    }

    if(email != ""){
        const email_result = EMAIL_REGEX.test(email);
        setValidEmail(email_result);
        checkVal = [...checkVal,email_result];
    }else{ 
        setValidEmail(false);
        checkVal = [...checkVal,false];
    }
       
    if(checkVal.includes(false)) { return false; } else { return true; }
}

const handleFundFormSubmit = async (e) => {
    e.preventDefault();        
    if(!fundFormSubmitCheck())
    {
        setErrMsg("");
        return;
    }
    else{
        setErrMsg('');
        setValidName(true);
        setValidCity(true);
        setValidLastName(true);
        setValidCountry(true);
        setIsValid(true);
        setValidEmail(true);
        setValidBroker(true);
        setValidEquity(true);
    }

    try {
        setContactFormButton(false);
        var phoneValueGet = '';
        if((isValidPhoneNumber((phoneValue || ''))) == true)
        {
            const phoneNumber = parsePhoneNumber(phoneValue);
            if (phoneNumber) {
                phoneValueGet = `${phoneNumber.number}`;
            }
        }
        const response = await axios.post(API_HOST+'/xhr/save-fund-request.php', { 
            name : `${name}`,
            last_name : `${lastName}`,
            phone : `${phoneValueGet}`,
            email : `${email}`,
            country : `${country}`,
            city : `${city}`,
            broker : `${broker}`,
            equity : `${equity}`,
        },{headers: {
            "Content-Type": "multipart/form-data",
            withCredentials: true
        }},)
        .then(response => {
            if(response.data.error == '0' || response.data.error == 0)
            {
                setErrMsg('');
                setName('');
                setValidName(true);
                setNameMsg('');
                setLastName('');
                setValidLastName(true);
                setLastNameMsg('');
                setCountry('');
                setValidCountry(true);
                setCountryMsg('');  
                setCity('');
                setValidCity(true);
                setCityMsg('');                  
                setEmail('');
                setValidEmail(true);
                setEmailMsg('');
                setPhoneValue('');
                setIsValid(true);
                setPhoneMsg('');
                setBroker('');
                setValidBroker(true);
                setBrokerMsg('');
                setEquity('');
                setValidEquity(true);
                setEquityMsg('');
                setContactFormButton(true);
                Toast.fire({
                  icon: 'success',
                  title: `${response.data.msg}`
                });
                setTimeout(() => {
                  window.location.reload();
                }, 100);
               
                //$('#GetFundedModal').modal('hide');
            }                
        })
        .catch(error => {
            if(!error?.response)
            {
                Toast.fire({
                    icon: 'error',
                    title: "No Server Response."
                });
            }
            else if(error.response.data.error == 1)
            {
                if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                {   
                    if('name' in error.response.data.data)
                    {
                        setNameMsg(error.response.data.data.name.toString());
                        setValidName(false);
                    }

                    if('lastName' in error.response.data.data)
                    {
                        setLastNameMsg(error.response.data.data.lastName.toString());
                        setValidLastName(false);
                    }

                    if('phone' in error.response.data.data)
                    {
                        setPhoneMsg(error.response.data.data.phone.toString());
                        setIsValid(false);
                    }

                    if('email' in error.response.data.data)
                    {
                        setEmailMsg(error.response.data.data.email.toString());
                        setValidEmail(false);
                    }

                    if('broker' in error.response.data.data)
                    {
                        setBrokerMsg(error.response.data.data.broker.toString());
                        setValidBroker(false);
                    }

                    if('equity' in error.response.data.data)
                    {
                        setEquityMsg(error.response.data.data.broker.toString());
                        setValidEquity(false);
                    }
                }  
                Toast.fire({
                    icon: 'error',
                    title: "contact us not send."
                });                  
            }
            else if(error.response?.status === 409 )
            {
                Toast.fire({
                    icon: 'error',
                    title: "contact us not send."
                });
            }
            else{
                Toast.fire({
                    icon: 'error',
                    title: "contact us not send."
                });
            }
            setContactFormButton(true);
        });
        setContactFormButton(true);
    } catch (err) {
        setContactFormButton(true);
        console.error('Error fetching data catch:', err);
    }
}

// const bookContainerRef = useRef(null);

// const handleViewPDFClick = () => {
//   // Programmatically trigger a click event on the book-container link
//   if (bookContainerRef.current) {
//     bookContainerRef.current.click();
//   }
// };


// --- ---
  const [isFlipBookActive, setIsFlipBookActive] = useState(false);

  const handleFlipBookOpen = () => {
    setIsFlipBookActive(true);
  }

  const handleFlipBookClose = () => {
    setIsFlipBookActive(false);
  }
// --- ---


  return (
    <>
      <CommonHelmet 
        title="Funded Account Forex | Top Free Funded Forex Account |  WINPROFX" 
        description="Start trading with a Funded Account Forex and start risking no money. Get free funded forex accounts from the top platforms. Join Now."
        keywords="Funded Account Forex, Funded Forex Account, Free Funded Forex Account, Free Funded Accounts, Free Funded Accounts:, Forex Funded Accounts, Funded Trading Accounts, No Deposit Trading Accounts, WinProFX Free Trading Capital, Forex Trading Capital, Funded Trading Opportunities, Trading Account Funding, Free Forex Trading Accounts, WinProFX Funded Accounts, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Best Forex Trading Platform"
      />

      <div className="d-none" style={{display:"none"}}>
          <h1>Funded Account Forex | Top Free Funded Forex Account |  WINPROFX</h1>
          <h2>Start trading with a Funded Account Forex and start risking no money. Get free funded forex accounts from the top platforms. Join Now.</h2>
          <b>Funded Account Forex</b>
          <b>Funded Forex Account</b>
          <b>Free Funded Forex Account</b>
          <b>Free Funded Accounts</b>
          <b>Free Funded Accounts:</b>
          <b>Best Forex Trading Broker Platform</b>
          <b>Best Forex Market Trading Platform</b>
          <b>Best Online Trading Platform For Professional Traders In India</b>
          <b>Best Forex Market Trading Platforms For Advanced Traders</b>
          <b>Best Forex Trading Platform For India Beginners</b>
          <b>Best Trading Brokers For Beginners</b>
          <b>Best Crypto Currency Trading Platform</b>
          <b>Best Free Funded Forex Account Without Deposit</b>
          <b>Forex Broker 0 Spread</b>
          <b>Free Funded Account</b>
          <b>Best Forex Trading Platform</b>
          <i>Funded Account Forex</i>
          <i>Funded Forex Account</i>
          <i>Free Funded Forex Account</i>
          <i>Free Funded Accounts</i>
          <i>Free Funded Accounts:</i>
          <i>Free Funded Account</i>
          <img src="" alt="Funded Account Forex, Funded Forex Account, Free Funded Forex Account, Free Funded Accounts, Free Funded Accounts:, Forex Funded Accounts, Funded Trading Accounts, No Deposit Trading Accounts, WinProFX Free Trading Capital, Forex Trading Capital, Funded Trading Opportunities, Trading Account Funding, Free Forex Trading Accounts, WinProFX Funded Accounts, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Best Forex Trading Platform" />
      </div>

      <div className="video-bg">
        {/* <video poster={videoImg1} autoPlay muted loop playsinline>
          <source src={video1} type=''/> 
        </video> */}
        <img src={videoImg1} alt='Free Funded Accounts: Video' />
      </div>

      <section className="relative">
        <div className="container-lg cl-custome">

          <div className="textlayout-main-bx">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Free Funded Accounts:</div>
            </div>
            <div data-aos="fade-up" className="tlm-sub-text">START FUNDED TRADING WITH WINPROFX</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-heading">Elevate Your <span className="tlm-h-color">Trading Potential</span> with <br/> Substantial Capital.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-sub-text">Unleash the full scope of your trading capabilities through WinproFX's innovative Funded Trading Program. Designed to empower aspiring professionals, this program, which includes access to a Free Funded Accounts:, opens doors to significant trading capital, propelling you toward greater financial success.</div>
            <div data-aos="fade-up" data-aos-delay="600" className="tlm-btns">
                <div className="tlm-btn-items tbi-fill" data-bs-target="#GetFundedModal" data-bs-toggle="modal"><span>Get Funded Today</span></div>
            </div>
          </div>
        </div>

      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">

            <div className="row justify-content-center mb-4">
              <div className="col-lg-8">
                <div data-aos="fade-up" className="common-img-bx cedgsr fcaegsr">
                  <div className='px-4'>
                    <img src={mobile} alt="Free Funded Accounts: Mobile" />
                  </div>
                </div>
              </div>
            </div>

            <div className="common-heading-bx text-center">
              <div className="common-heading">Unlock Your Trading Odyssey</div>
              <div className="common-sub-heading">Capital Boost, Performance Symphony, Risk Harmony, and Prosperous Sharing with WinproFX Funded Trading.</div>
              <div className="common-sub-heading">Free Funded Accounts:</div>
            </div>

            <div className="row justify-content-center mt-5">
              <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                  <div className="card-item funded-card-item">
                      <div className="card-i-heading color-electricblue">Capital Amplification</div>
                      <div className="card-i-text max-width-189">Access substantial trading capital to diversify your portfolio and explore new market opportunities.</div>
                  </div>
              </div>
              <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                  <div className="card-item funded-card-item">
                      <div className="card-i-heading color-electricblue">Performance-Driven Funding</div>
                      <div className="card-i-text max-width-189">Your success drives our commitment. Benefit from funding that aligns with your trading performance, rewarding consistency and strategic prowess.</div>
                  </div>
              </div>
              <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                  <div className="card-item funded-card-item">
                      <div className="card-i-heading color-electricblue">Risk Management Protocols</div>
                      <div className="card-i-text max-width-189">Trade with confidence under our robust risk management framework, ensuring a secure trading environment.</div>
                  </div>
              </div>
              <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                  <div className="card-item funded-card-item">
                      <div className="card-i-heading color-electricblue">Flexible Profit Sharing</div>
                      <div className="card-i-text max-width-189">Enjoy a fair and adaptable profit-sharing model that transforms your success into mutual prosperity.</div>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">

            <div className="row justify-content-center mb-4">
              <div className="col-lg-9">
                <div data-aos="fade-up" className="common-img-bx cedgsr">
                  <div className='px-4'>
                    <img src={laptop} alt="Free Funded Desktop" />
                    <img src={ellipse3} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="common-heading-bx text-center">
              <div className="common-heading">Seize Success</div>
              <div className="common-sub-heading">Free Funded Accounts:</div>
              <div className="common-sub-heading">Navigate Your Trading Journey from Application to <br/> Prosperity with WinproFX Funded Trading.</div>
            </div>
       
            <div className="row justify-content-center mt-5">
              <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                  <div className="card-item funded-card-item">
                      <div className="card-i-num">1</div>
                      <div className="card-i-heading">Application</div>
                      <div className="card-i-text max-width-189">Begin your journey by applying for our Funded Trading Program. Showcase your skills, strategies, and dedication to success.</div>
                  </div>
              </div>
              <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                  <div className="card-item funded-card-item">
                      <div className="card-i-num">2</div>
                      <div className="card-i-heading">Evaluation</div>
                      <div className="card-i-text max-width-189">Our expert team assesses your trading performance, considering factors such as risk management, consistency, and adherence to guidelines.</div>
                  </div>
              </div>
              <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                  <div className="card-item funded-card-item">
                      <div className="card-i-num">3</div>
                      <div className="card-i-heading">Funding Approval</div>
                      <div className="card-i-text max-width-189">Upon successful evaluation, gain approval for funded trading and unlock access to our trading capital.</div>
                  </div>
              </div>
              <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                  <div className="card-item funded-card-item">
                      <div className="card-i-num">4</div>
                      <div className="card-i-heading">Trade and Prosper</div>
                      <div className="card-i-text max-width-189">Engage confidently in the markets, knowing that your success is intrinsically linked with our shared objectives.</div>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-2 pb-2">
        <div className="container-lg cl-custome">
            <div className="brochure-main-bx">

            {/* <div className="bmb-img d-none">
              <LightGallery  plugins={[lgZoom]}>
                <a data-aos="fade-right" data-aos-delay="800" className="book-container" href={brochureImg} ref={bookContainerRef}>
                  <div className="book-big">
                    <img src={brochureposter} alt='' />
                  </div>
                </a>
                <a href={brochureImg2}></a>
                <a href={brochureImg3}></a>
                <a href={brochureImg4}></a>
                <a href={brochureImg5}></a>
              </LightGallery>
            </div> */}

            <div className="bmb-img">
                <a data-aos="fade-right" data-aos-delay="800" className="book-container" onClick={handleFlipBookOpen}>
                  <div className="book-big">
                    <img src={brochureposter} alt="Free Funded Accounts: PDF" />
                  </div>
                </a>
            </div>
            <div className="bmb-btns">
              {/* <a data-aos="fade-up" data-aos-delay="900" className="common-btn-item cbi-fill" onClick={handleFlipBookOpen}>
                <span>{EyeSvg()} View PDF</span>
              </a> */}
              <a data-aos="fade-up" data-aos-delay="900" className="common-btn-item cbi-fill" href={brochurePDF} target='_blank'>
                <span>{EyeSvg()} View PDF</span>
              </a>
              <a data-aos="fade-up" data-aos-delay="1000" className="common-btn-item cbi-outline" href={brochurePDF} download>
                <span>{downloadSvg()} Download PDF</span>
              </a>
            </div>

            </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Empower Your Trades: WinproFX Funded Trading Awaits!</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Join WinproFX Funded Trading today and elevate your trading experience. Empower your potential, seize opportunities, and chart a course for financial success with WinproFX Free Funded Accounts:.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                <div className="tlm-btn-items tbi-fill"  data-bs-target="#GetFundedModal" data-bs-toggle="modal"><span>Get Funded Today</span></div>
            </div> 
          </div>
        </div>
      </section>

      <section className={`flipbook-popup ${isFlipBookActive ? 'active' : ''}`}>
        <span className="close-icon" onClick={handleFlipBookClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
        </span>
        <FlipBook/>
        <div className="fliobook-overlayer" onClick={handleFlipBookClose}></div>
      </section>


      {/* Get Funded Modal Popup */}
      <div className="modal fade zoom" id="GetFundedModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content custom-content">
                  <div className="custom-modal-header">
                      {/* <div className="cmh-lable">Free Funded Accounts:</div> */}

                      <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                          </svg>
                      </span>
                  </div>
                  <div className="custom-modal-data mt-2">
                      <form onSubmit={handleFundFormSubmit} className="px-3 pb-4">
                          <div className={`otp-sent-msg text-start mb-4 ${errMsg ? "" : "d-none"} `}>
                            <p ref={errRef} className={`form-error-msg text-start ${errMsg ? "" : "d-none"} `} aria-live="assertive" >{errMsg}</p>
                          </div>
                          <div className="row">

                            <div className="col-6">
                              <div className={`signinup-group ${name=='' && !validName ? "error-group" : ""} `}>
                                  <div className="group__label">First name</div>
                                  <input type="text" ref={nameRef} placeholder="Enter first name here.." onChange={(e) => checkName(e.target.value)} aria-invalid={validName ? "false" : "true"} aria-describedby="name_error" />
                                  <span id="name_error" className={`form-error-msg mb-1 ${name=='' && !validName ? "" : "d-none"} `}>{nameMsg != '' ? nameMsg : 'Please enter firstname'}</span>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className={`signinup-group ${lastName=='' && !validLastName ? "error-group" : ""} `}>
                                  <div className="group__label">Last Name</div>
                                  <input type="text" placeholder="Enter last name here.." ref={lastNameRef} onChange={(e) => checkLastName(e.target.value)} aria-invalid={validLastName ? "false" : "true"} aria-describedby="last_name_error"/>
                                  <span id="last_name_error" className={`form-error-msg mb-1 ${lastName=='' && !validLastName ? "" : "d-none"} `}>{lastNameMsg != '' ? lastNameMsg : 'Please enter lastname'}</span>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className={`signinup-group sg-country-code ${phoneValue=='' && !isValid ? "error-group" : ""} `}>
                                  <div className="group__label">Phone No.</div>
                                  <PhoneInput 
                                  // international 
                                  countryCallingCodeEditable={false} defaultCountry="AE"  placeholder="Enter phone number" id="phone" ref={phoneRef} value={phoneValue} onChange={handleInputChange}/>
                                  {/* <input type="number" placeholder="Enter your Phone no. here"/>
                                  <span className="group_left_icon">+1</span> */}
                                  <span id="phone_error" className={`form-error-msg mb-1 ${!isValid ? "" : "d-none"} `}>{phoneMsg != '' ? phoneMsg : 'Please enter valid number'}</span>
                              </div>      
                            </div>

                            <div className="col-sm-6">
                            <div className={`signinup-group ${email=='' && !validEmail ? "error-group" : ""} `}>
                                <label className="group__label">Email</label>
                                <input type="email" id="email" ref={emailRef} autoComplete="off" onChange={(e) => checkEmail(e.target.value)} aria-invalid={validEmail ? "false" : "true"} aria-describedby="email_error" placeholder="Enter your email here.." value={email} />
                                <span id="email_error" className={`form-error-msg mb-1 ${!validEmail ? "" : "d-none"} `}>{emailMsg != '' ? emailMsg : 'Please enter valid email'}</span>
                            </div>
                            </div>

                            <div className="col-6">
                              <div className={`signinup-group sgri ${country=='' && !validCountry ? "error-group" : ""}`}>
                                  <label className="group__label">Country</label>
                                  <select id="country" ref={countryRef} onChange={(e) => checkCountry(e.target.value)}  aria-invalid={validCountry ? "false" : "true"} aria-describedby="country_error" value={country} >
                                      <option value="">Select Country</option>
                                      {countries.map((country, index) => (
                                        <option key={index} value={country}>{country}</option>
                                    ))}                
                                  </select>
                                  <span className="down-icon"></span>
                                  <span id="country_error" className={`form-error-msg ${country=='' && !validCountry ? "" : "d-none"} `}>{countryMsg != '' ? countryMsg : 'Please Select Country'}</span>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className={`signinup-group ${city=='' && !validCity ? "error-group" : ""} `}>
                                  <div className="group__label">City</div>
                                  <input type="text" placeholder="Enter city here .." ref={cityRef} onChange={(e) => checkCity(e.target.value)} aria-invalid={validCity ? "false" : "true"} aria-describedby="city_error"/>
                                  <span id="city_error" className={`form-error-msg mb-1 ${city=='' && !validCity ? "" : "d-none"} `}>{cityMsg != '' ? cityMsg : 'Please enter city'}</span>
                              </div>
                            </div>

                            {/* <div className="col-6">
                              <div className="signinup-group sgri">
                                  <div className="group__label">City</div>
                                  <select>
                                    <option>Select City</option>
                                    <option>Ahmedabad</option>
                                    <option>Mumbai</option>
                                    <option>Delhi</option>
                                  </select>
                                  <div className="down-icon"></div>
                              </div>
                            </div> */}

                            <div className="col-6">
                              <div className={`signinup-group ${broker=='' && !validBroker ? "error-group" : ""} `}>
                                  <div className="group__label">Current Broker</div>
                                  <input type="text" placeholder="Enter your broker name here..." ref={brokerRef} onChange={(e) => checkBroker(e.target.value)} aria-invalid={validBroker ? "false" : "true"} aria-describedby="broker_error"/>
                                  <span id="broker_error" className={`form-error-msg mb-1 ${broker=='' && !validBroker ? "" : "d-none"} `}>{brokerMsg != '' ? brokerMsg : 'Please enter brokername'}</span>
                                  {/* <select>
                                    <option>Select your current broker</option>
                                    <option>James Smith</option>
                                    <option>Jonas salk</option>
                                    <option>emma watson</option>
                                  </select>
                                  <div className="down-icon"></div> */}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className={`signinup-group ${equity=='' && !validEquity ? "error-group" : ""} `}>
                                  <div className="group__label">Current Equity size</div>
                                  <input type="number" placeholder="Enter your equity size here..." ref={equityRef} onChange={(e) => checkEquity(e.target.value)} aria-invalid={validEquity ? "false" : "true"} aria-describedby="broker_error"/>
                                  <span id="broker_error" className={`form-error-msg mb-1 ${broker=='' && !validEquity ? "" : "d-none"} `}>{equityMsg != '' ? equityMsg : 'Please enter equity size'}</span>
                                  {/* <select>
                                    <option>Select your current broker</option>
                                    <option>James Smith</option>
                                    <option>Jonas salk</option>
                                    <option>emma watson</option>
                                  </select>
                                  <div className="down-icon"></div> */}
                              </div>
                            </div>

                          </div>
                          <button style={{width:"100%"}}  className={`width576full common-btn-item cbi-fill mt-2 ${contactFormButton ? '' : 'd-none'} `}><span>Continue</span></button>
                          <button type="button" className={`width576full common-btn-item cbi-fill mt-2 lsb-loader-btn ${contactFormButton ? 'd-none' : ''} `}>
                            <img src={Img.loading} alt="" />Please wait ....
                          </button>
                      </form>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};
